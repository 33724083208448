'use strict';

export function isElementFullyInViewport(el) {
    let rect = el.getBoundingClientRect();

    return (rect.height > 0 || rect.width > 0) &&
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth);
}

export function isElementInViewport(el) {
    let rect = el.getBoundingClientRect();

    return (rect.height > 0 || rect.width > 0) &&
        rect.bottom >= 0 &&
        rect.right >= 0 &&
        rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left <= (window.innerWidth || document.documentElement.clientWidth)
}

export function offsetTop(el) {
    let rect = el.getBoundingClientRect();

    return rect.top;
}