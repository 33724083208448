'use strict';

import {CountUp} from 'countup.js';

import {isElementInViewport, offsetTop} from './boundingClientRect';

document.addEventListener('DOMContentLoaded', () => {

    initCounters();

    function initCounters() {
        const numbersLists = document.querySelectorAll('.numbers-list');

        Array.prototype.forEach.call(numbersLists, (singleNumberList) => {
            let run = false;
            const countNumbers = singleNumberList.querySelectorAll('.value');
            let counters = [];

            Array.prototype.forEach.call(countNumbers, (number) => {
                const numValue = number.dataset.value;
                const decimals = number.dataset.decimals;

                const options = {useEasing: true, useGrouping: true, decimal: '.', duration: 3};
                const counter = new CountUp(number, numValue, options);
                counters.push(counter);
            });

            if (isElementInViewport(singleNumberList)) {
                startCounters();
            }

            document.addEventListener('scroll', () => {
                if (isElementInViewport(singleNumberList) && !run) {
                    startCounters();
                }
            });

            function startCounters() {
                run = true;
                setTimeout(() => {
                    Array.prototype.forEach.call(counters, (counter) => {
                        counter.start();
                    });
                }, 500);
            }
        });

    }
});