'use strict';

import 'bootstrap';
import Swiper from 'swiper';
import './charts';
import './hero';
import './counter';
import './tableFilter';

import {isElementInViewport, offsetTop} from './boundingClientRect';

document.addEventListener('DOMContentLoaded', () => {
    const nav = document.querySelector('.header .header-bottom .navbar');
    let navTop = 0;

    // console.log(nav, navTop);
    window.addEventListener('scroll', () => {
        stickyNavigation();
        parallaxImg();
    });
    window.addEventListener('load', () => {
        getNavTopSize();
        stickyNavigation();
        parallaxImg();
    });
    window.addEventListener('resize', () => {
        getNavTopSize();
        stickyNavigation();
        parallaxImg();
    });

    function getNavTopSize() {
        const offsetParent = nav.parentElement;
        const offsetTop = offsetParent.offsetTop;
        navTop = offsetTop;
    }

    function stickyNavigation() {
        if (window.scrollY >= navTop) {
            // nav offsetHeight = height of nav
            document.body.style.paddingTop = nav.offsetHeight + 'px';
            nav.classList.add('fixed-top');
        } else {
            document.body.style.paddingTop = 0;
            nav.classList.remove('fixed-top');
        }
    }

    function parallaxImg() {
        const parallaxImages = document.querySelectorAll('.parallax-img');

        Array.prototype.forEach.call(parallaxImages, function (singleBlock) {
            const parallaxImg = singleBlock.querySelector('img');

            if ((window.innerWidth || document.documentElement.clientWidth) >= 768) {
                let currentOffsetTop = offsetTop(singleBlock),
                    offset = (window.scrollY - currentOffsetTop) * .05,
                    blockVisible = isElementInViewport(singleBlock);

                if (blockVisible && offset > 0) {
                    parallaxImg.style.transform = 'translateY(' + offset + 'px)';
                } else {
                    if (offset < 0)
                        parallaxImg.style.transform = 'translateY(0)';
                }
            } else {
                parallaxImg.style.transform = 'translateY(0)';
            }
        });
    }

    const configChartSwiper = {
        speed: 400,
        slidesPerColumnFill: 'row',
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        }
    };

    const autoPlay3Sec = document.querySelector(".auto-play");

    if (autoPlay3Sec !== null) {
        configChartSwiper.autoplay = {
            delay: autoPlay3Sec.dataset.delayMs,
        };
    }

    const chartSwiper = new Swiper('.chart-slider .swiper-container', configChartSwiper);


    const latestNewsSwiper = new Swiper('.latest-news .swiper-container', {
        watchOverflow: true,
        slidesPerView: 1,
        spaceBetween: 30,
        breakpointsInverse: true,
        breakpoints: {
            480: {
                slidesPerView: 2
            },
            1200: {
                slidesPerView: 3
            }
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            lockClass: 'd-none'

        },
    });

    // third menu level
    const dropdownsThirdLevel = document.querySelectorAll('.dropdown-menu-item.dropdown-list > .dropdown-item');
    Array.prototype.forEach.call(dropdownsThirdLevel, function (dropdown) {
        dropdown.addEventListener('click', (e) => {
            const target = e.currentTarget,
                parent = target.parentNode,
                dropdownMenu = parent.querySelector('.dropdown-menu');
            e.preventDefault();
            e.cancelBubble = true;

            if (!target.classList.contains('show')) {
                parent.classList.add('show');
                target.classList.add('show');
                dropdownMenu.classList.add('show');
            } else {
                parent.classList.remove('show');
                target.classList.remove('show');
                dropdownMenu.classList.remove('show');
            }
        })
    });

    const overviewBtn = document.querySelector('.overview .btn');
    if (overviewBtn) {
        overviewBtn.addEventListener('click', (e) => {
            e.preventDefault();
            const target = document.querySelector(e.currentTarget.hash);

            if (!target.classList.contains('show')) {
                target.classList.add('show');
            } else {
                target.classList.remove('show');
            }
        });
    }
});