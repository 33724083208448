'use strict';

import Swiper from 'swiper';
import {isElementInViewport, offsetTop} from './boundingClientRect';

document.addEventListener('DOMContentLoaded', () => {

    const config = {
        speed: 400,
        effect: 'fade',
        watchOverflow: true,
        navigation: {
            nextEl: '.hero .swiper-button-next',
            prevEl: '.hero .swiper-button-prev',
            lockClass: 'd-none'
        },
    };

    const isOnlyOne = document.querySelector(".swipper-no-slides");

    if (isOnlyOne === null) {
        config.loop = true;
        config.autoplay = {
            delay: 7400,
        };
    }

    const heroSwiper = new Swiper('.hero .swiper-container', config);

    const heroImages = document.querySelectorAll('.hero.hero-image');
    Array.prototype.forEach.call(heroImages, (heroImage) => {
        let showed = false;

        // show on load if in viewport
        isElementInViewport(heroImage) && show();

        document.addEventListener('scroll', () => {
            if (isElementInViewport(heroImage) && !showed) {
                show();
            }
        });

        function show() {
            showed = true;
            heroImage.querySelector('.hero-image-container').classList.add('show');
        }
    });
});