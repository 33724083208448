'use strict';

import List from 'list.js';
import {CountUp} from "countup.js";
import {isElementInViewport} from "./boundingClientRect";

document.addEventListener('DOMContentLoaded', () => {
    const listOptions = {
        valueNames: [
            'code',
            'country',
            'title',
            'sector',
            'status',
            {
                data: [
                    'regional',
                    'countryCode',
                    'sectorCode',
                    'statusCode',
                    'ifiCode',
                    'beneficiaryCode'
                ]
            }
        ]
    }

    const filterList = new List('projects-list', listOptions);

    let filter = {};

    const filterSelectors = document.querySelectorAll('.filter select');
    Array.prototype.forEach.call(filterSelectors, (filterSelector) => {

        if (filterSelector.value !== "*") {
            initFilter(filterSelector.value, filterSelector.dataset.target);
        }
        filterSelector.addEventListener('change', (e) => {
            initFilter(e.target.value, e.target.dataset.target)
        });
    });

    const resetBtn = document.querySelector('#reset-project-filters');
    if (resetBtn) {
        resetBtn.addEventListener('click', (e) => {
            Array.prototype.forEach.call(listOptions['valueNames'][5].data, (item) => {
                delete filter[item];
            });
            filterList.filter((item) => {
                for (let target in filter) {
                    if (item.values()[target] !== filter[target]) {
                        return false;
                    }
                }
                return true;
            });
        });
    }

    function initFilter(code, target) {
        // update filter object
        if (code === '*') {
            delete filter[target];
        } else {
            filter[target] = code;
        }

        filterList.filter((item) => {
            for (let target in filter) {
                if (target === "countryCode" && filter[target] === "Regional" && item.values()['regional'] === "1") {
                    return true;
                }

                if (!item.values()[target].includes(filter[target])) {
                    return false;
                }
            }

            return true;
        });
    }

});

