'use strict';

import c3 from 'c3';
import Swiper from 'swiper';
import * as d3 from "d3-format";

document.addEventListener('DOMContentLoaded', () => {
    const overviewSwiper = new Swiper('.overview .swiper-container', {
        speed: 400,
        watchOverflow: true,
        slidesPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        }
    });

    const chartWidgets = document.querySelectorAll('.chart-item');
    Array.prototype.forEach.call(chartWidgets, (chart) => {
        const columns = getDataArray(chart.dataset.columns),
            view = chart.dataset.view,
            legend = +chart.dataset.legend,
            groups = +chart.dataset.groups,
            xAxic = chart.dataset.xaxic.split(',');

        let groupArr = [];

        if (groups) {
            columns.forEach((item) => {
                // add label of each dataset to group array
                groupArr.push(item[0]);
            });
        }

        let chartC3;
        if (view === 'bar') {
            // add x-axic labels
            columns.push(xAxic);

            chartC3 = c3.generate({
                bindto: chart,
                data: {
                    x: 'x',
                    columns: columns,
                    type: view,
                    groups: [groupArr],
                    labels: {
                        format: function (value, ratio, id) {
                            return d3.format('.2s')(value);
                        }
                    }
                },
                legend: {
                    show: legend
                },
                axis: {
                    x: {
                        type: 'category',
                        tick: {
                            multiline: false
                        }
                    },
                    y: {
                        tick: {
                            format: d3.format(".2s")
                        }
                    }
                }
            });
        } else if (view === 'donut' || 'pie') {
            chartC3 = c3.generate({
                bindto: chart,
                data: {
                    columns: columns,
                    type: view
                },
                legend: {
                    show: legend
                },
                pie: {
                    label: {
                        format: function (value, ratio, id) {
                            return d3.format('.4s')(value);
                        }
                    }
                },
                donut: {
                    label: {
                        show: false
                    }
                }
            });
        }
    });

    function getDataArray(content) {
        let arr = content.split(';').map((item) => {
            if (item !== '') {
                let tmp = item.split(',');

                return tmp;
            }
        });
        return arr;
    }
});
